import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ClassNames from "classnames";

import { colorsMap } from "./../modules/constants";

const StyleguideWrapper = styled.div`
  .download {
    color: var(--gun-powder);

    &:hover {
      color: var(--terracotta);
    }

    span {
      font-size: 14px;
    }
  }
`;

const logosNoText = [
  {
    path: "../logo/logo-black.svg",
    helpers: "",
    label: "Download Logo Black as a SVG",
  },
  {
    path: "../logo/logo-black.png",
    helpers: "",
    label: "Download Logo Black as a PNG",
  },
  {
    path: "../logo/logo-white.svg",
    helpers: "bg-acapulco",
    label: "Download Logo White as a SVG",
  },
  {
    path: "../logo/logo-white.png",
    helpers: "bg-acapulco",
    label: "Download Logo White as a PNG",
  },
  {
    path: "../logo/logo-terracotta.svg",
    helpers: "",
    label: "Download Logo White as a SVG",
  },
  {
    path: "../logo/logo-terracotta.png",
    helpers: "",
    label: "Download Logo White as a PNG",
  },
  {
    path: "../logo/logo-acapulco.svg",
    helpers: "",
    label: "Download Logo Acapulco as a SVG",
  },
  {
    path: "../logo/logo-acapulco.png",
    helpers: "",
    label: "Download Logo Acapulco as a PNG",
  },
  {
    path: "../logo/logo-gun-powder.svg",
    helpers: "",
    label: "Download Logo Gun Powder as a SVG",
  },
  {
    path: "../logo/logo-gun-powder.png",
    helpers: "",
    label: "Download Logo Gun Powder as a PNG",
  },
];

const logosWithText = [
  {
    path: "../logo/logo-black-text.svg",
    helpers: "",
    label: "Download Logo Black with text as a SVG",
  },
  {
    path: "../logo/logo-black-text.png",
    helpers: "",
    label: "Download Logo Black with text as a PNG",
  },
  {
    path: "../logo/logo-white-text.svg",
    helpers: "bg-acapulco",
    label: "Download Logo White with text as a SVG",
  },
  {
    path: "../logo/logo-white-text.png",
    helpers: "bg-acapulco",
    label: "Download Logo White with text as a PNG",
  },
  {
    path: "../logo/logo-terracotta-text.svg",
    helpers: "",
    label: "Download Logo White with text as a SVG",
  },
  {
    path: "../logo/logo-terracotta-text.png",
    helpers: "",
    label: "Download Logo White with text as a PNG",
  },
  {
    path: "../logo/logo-acapulco-text.svg",
    helpers: "",
    label: "Download Logo Acapulco with text as a SVG",
  },
  {
    path: "../logo/logo-acapulco-text.png",
    helpers: "",
    label: "Download Logo Acapulco with text as a PNG",
  },
  {
    path: "../logo/logo-gun-powder-text.svg",
    helpers: "",
    label: "Download Logo Gun Powder with text as a SVG",
  },
  {
    path: "../logo/logo-gun-powder-text.png",
    helpers: "",
    label: "Download Logo Gun Powder as a PNG",
  },
];

const Styleguide = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noimageindex, nofollow, nosnippet" />
      </Helmet>
      <StyleguideWrapper className="mb-5">
        <Container>
          <Row>
            <Col>
              <h1 className="my-5">Colors</h1>
              <hr className="mb-5" />
            </Col>
          </Row>
          <Row>
            {colorsMap.map((color, index) => (
              <Col key={index} md={3}>
                <div
                  style={{ backgroundColor: color.value }}
                  className="p-2 mb-3 text-center border"
                >
                  <h2
                    className={ClassNames(
                      { "text-white": color.name === "gun-powder" },
                      { "text-white": color.name === "black" }
                    )}
                  >
                    {color.name}
                  </h2>
                  <p
                    className={ClassNames(
                      { "text-white": color.name === "gun-powder" },
                      { "text-white": color.name === "black" }
                    )}
                  >
                    {color.value}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <h1 className="my-5">
                Typography (Ubuntu){" "}
                <span>
                  <a href={"/Ubuntu.zip"} className="download" download>
                    Get the fonts here!
                  </a>
                </span>
              </h1>
              <hr className="mb-5" />
              <h2 className="mb-5" style={{ fontWeight: 700 }}>
                Bold text (heading)
              </h2>
              <p className="mb-5" style={{ fontWeight: 400 }}>
                Regular text (paragraph)
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h1 className="my-5">Logo</h1>
              <h2 className="mb-5">Logo with no text</h2>
              <hr className="mb-5" />
            </Col>
            {logosNoText.map((logo, index) => (
              <Col sm={6} md={3} xl={2} className="p-2" key={index}>
                <a
                  href={logo.path}
                  className="d-flex flex-column text-center download"
                  download
                >
                  <span className="pb-2">{logo.label}</span>
                  <img
                    src={logo.path}
                    alt={logo.label}
                    width="100%"
                    height="100%"
                    className={`mx-auto p-4 ${logo.helpers}`}
                  />
                </a>
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs={12}>
              <h2 className="my-5">Logo with text</h2>
              <hr className="mb-5" />
            </Col>

            {logosWithText.map((logo, index) => (
              <Col sm={6} md={3} xl={2} className="p-2" key={index}>
                <a
                  href={logo.path}
                  className="d-flex flex-column text-center download"
                  download
                >
                  <span className="pb-2">{logo.label}</span>
                  <img
                    src={logo.path}
                    alt={logo.label}
                    width="100%"
                    height="100%"
                    className={`mx-auto p-4 ${logo.helpers}`}
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </StyleguideWrapper>
    </>
  );
};
export default Styleguide;
